import { Component } from '@angular/core';


@Component({
  selector: 'lc-root',
  standalone: true,
  templateUrl: './_.component.html',
  styleUrls: ['./_.component.scss'],
  imports: [],
})
export class UnavailableComponent {}
