import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import {
  IPayment,
  IQuery,
  IResponsePagination,
  TResponsePagination,
} from 'interfaces';

@Injectable({
  providedIn: 'platform',
})
export class AuctionService {
  protected readonly http = inject(HttpClient);

  public find(query: IQuery): Observable<TResponsePagination<any[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http
      .get<IResponsePagination<IPayment[]>>(`@api/auctions`, { params })
      .pipe(map((response) => response.data));
  }


  public auctionsTypesCount(): Observable<any> {
    return this.http
      .get<IResponsePagination<IPayment[]>>(`@api/auctions/types-count`)
      .pipe(map((response) => response.data));
  }

  public auctionsVarietiesCount(): Observable<any> {
    return this.http
      .get<IResponsePagination<IPayment[]>>(`@api/auctions/varieties-count`)
      .pipe(map((response) => response.data));
  }

  public auctionsRaritiesCount(): Observable<any> {
    return this.http
      .get<IResponsePagination<IPayment[]>>(`@api/auctions/rarities-count`)
      .pipe(map((response) => response.data));
  }
}
