<mat-toolbar class="d-flex justify-content-between">
  <button mat-icon-button routerLink="/home" routerLinkActive>
    <i class="ri-home-8-line"></i>
  </button>

  <button mat-icon-button routerLink="/battle" routerLinkActive>
    <i class="ri-sword-line"></i>
  </button>

  <button mat-icon-button routerLink="/auctions" routerLinkActive>
    <i class="ri-auction-line"></i>
  </button>

  <button mat-icon-button routerLink="/community/friends" routerLinkActive>
    <i class="ri-user-community-line"></i>
  </button>
</mat-toolbar>
  