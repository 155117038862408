export enum EItemSampleType {
  WEAPON = 'WEAPON',
  ARMOR = 'ARMOR',
  HELMET = 'HELMET',
  GLOVES = 'GLOVES',
  BOOTS = 'BOOTS',
  BELT = 'BELT',
  RING = 'RING',
  NECKLACE = 'NECKLACE',
  POTION = 'POTION',
  SCROLL = 'SCROLL',
  GEM = 'GEM',
  MATERIAL = 'MATERIAL',
}

export enum EItemSampleWeapon {
  WEAPON_SWORD_ONE_HANDED = 'WEAPON_SWORD_ONE_HANDED',
  WEAPON_SWORD_TWO_HANDED = 'WEAPON_SWORD_TWO_HANDED',
  WEAPON_AXE_ONE_HANDED = 'WEAPON_AXE_ONE_HANDED',
  WEAPON_AXE_TWO_HANDED = 'WEAPON_AXE_TWO_HANDED',
  WEAPON_DAGGER = 'WEAPON_DAGGER',
  WEAPON_DAGGER_DUAL = 'WEAPON_DAGGER_DUAL',
  WEAPON_BOW = 'WEAPON_BOW',
  WEAPON_CROSSBOW = 'WEAPON_CROSSBOW',
  WEAPON_STAFF = 'WEAPON_STAFF',
  WEAPON_WAND = 'WEAPON_WAND',
  WEAPON_SHIELD = 'WEAPON_SHIELD',
  WEAPON_MACE_ONE_HANDED = 'WEAPON_MACE_ONE_HANDED',
  WEAPON_MACE_TWO_HANDED = 'WEAPON_MACE_TWO_HANDED',
  WEAPON_SPEAR = 'WEAPON_SPEAR',
  WEAPON_HALBERD = 'WEAPON_HALBERD',
}

export enum EItemSampleArmor {
  ARMOR_LIGHT = 'ARMOR_LIGHT',
  ARMOR_MEDIUM = 'ARMOR_MEDIUM',
  ARMOR_HEAVY = 'ARMOR_HEAVY',
  ARMOR_ROBE = 'ARMOR_ROBE',
  ARMOR_CLOTH = 'ARMOR_CLOTH',
}

export enum EItemSampleHelmet {
  HELMET_LIGHT = 'HELMET_LIGHT',
  HELMET_MEDIUM = 'HELMET_MEDIUM',
  HELMET_HEAVY = 'HELMET_HEAVY',
  HELMET_MAGIC = 'HELMET_MAGIC',
}

export enum EItemSampleGloves {
  GLOVES_LIGHT = 'GLOVES_LIGHT',
  GLOVES_MEDIUM = 'GLOVES_MEDIUM',
  GLOVES_HEAVY = 'GLOVES_HEAVY',
  GLOVES_MAGIC = 'GLOVES_MAGIC',
}

export enum EItemSampleBoots {
  BOOTS_LIGHT = 'BOOTS_LIGHT',
  BOOTS_MEDIUM = 'BOOTS_MEDIUM',
  BOOTS_HEAVY = 'BOOTS_HEAVY',
  BOOTS_MAGIC = 'BOOTS_MAGIC',
}

export enum EItemSampleBelt {
  BELT_LIGHT = 'BELT_LIGHT',
  BELT_MEDIUM = 'BELT_MEDIUM',
  BELT_HEAVY = 'BELT_HEAVY',
  BELT_MAGIC = 'BELT_MAGIC',
}

export enum EItemSampleRing {
  RING_SIMPLE = 'RING_SIMPLE',
  RING_MAGIC = 'RING_MAGIC',
  RING_RARE = 'RING_RARE',
  RING_LEGENDARY = 'RING_LEGENDARY',
}

export enum EItemSampleNecklace {
  NECKLACE_SIMPLE = 'NECKLACE_SIMPLE',
  NECKLACE_MAGIC = 'NECKLACE_MAGIC',
  NECKLACE_RARE = 'NECKLACE_RARE',
  NECKLACE_LEGENDARY = 'NECKLACE_LEGENDARY',
}

export enum EItemSamplePotion {
  POTION_HEALTH = 'POTION_HEALTH',
  POTION_MANA = 'POTION_MANA',
  POTION_STAMINA = 'POTION_STAMINA',
  POTION_BUFF = 'POTION_BUFF',
}

export enum EItemSampleScroll {
  SCROLL_FIREBALL = 'SCROLL_FIREBALL',
  SCROLL_HEALING = 'SCROLL_HEALING',
  SCROLL_TELEPORTATION = 'SCROLL_TELEPORTATION',
  SCROLL_IDENTIFICATION = 'SCROLL_IDENTIFICATION',
}

export enum EItemSampleGem {
  GEM_RUBY = 'GEM_RUBY',
  GEM_SAPPHIRE = 'GEM_SAPPHIRE',
  GEM_EMERALD = 'GEM_EMERALD',
  GEM_DIAMOND = 'GEM_DIAMOND',
}

export enum EItemSampleMaterial {
  MATERIAL_WOOD = 'MATERIAL_WOOD',
  MATERIAL_IRON = 'MATERIAL_IRON',
  MATERIAL_STEEL = 'MATERIAL_STEEL',
  MATERIAL_LEATHER = 'MATERIAL_LEATHER',
  MATERIAL_CLOTH = 'MATERIAL_CLOTH',
  MATERIAL_MAGIC_ESSENCE = 'MATERIAL_MAGIC_ESSENCE',
}

export type TItemSampleVariety =
  | EItemSampleWeapon
  | EItemSampleArmor
  | EItemSampleHelmet
  | EItemSampleGloves
  | EItemSampleBoots
  | EItemSampleBelt
  | EItemSampleRing
  | EItemSampleNecklace
  | EItemSamplePotion
  | EItemSampleScroll
  | EItemSampleGem
  | EItemSampleMaterial;

export const TItemSampleVariety = [
  ...Object.values(EItemSampleWeapon),
  ...Object.values(EItemSampleArmor),
  ...Object.values(EItemSampleHelmet),
  ...Object.values(EItemSampleGloves),
  ...Object.values(EItemSampleBoots),
  ...Object.values(EItemSampleBelt),
  ...Object.values(EItemSampleRing),
  ...Object.values(EItemSampleNecklace),
  ...Object.values(EItemSamplePotion),
  ...Object.values(EItemSampleScroll),
  ...Object.values(EItemSampleGem),
  ...Object.values(EItemSampleMaterial),
];

export enum EItemSampleRarity {
  COMMON = 'COMMON',
  UNCOMMON = 'UNCOMMON',
  RARE = 'RARE',
  EPIC = 'EPIC',
  LEGENDARY = 'LEGENDARY',
  MYTHIC = 'MYTHIC',
}

export interface IItemSampleInterface {
  id: string;

  title: string;
  description: string;
  image: string;

  type: EItemSampleType;
  variety: TItemSampleVariety;
  rarity: EItemSampleRarity;
}

export interface IItemSampleWeapon extends IItemSampleInterface {
  type: EItemSampleType.WEAPON;
  variety: EItemSampleWeapon;
}

export interface IItemSampleArmor extends IItemSampleInterface {
  type: EItemSampleType.ARMOR;
  variety: EItemSampleArmor;
}

export interface IItemSampleHelmet extends IItemSampleInterface {
  type: EItemSampleType.HELMET;
  variety: EItemSampleHelmet;
}

export interface IItemSampleGloves extends IItemSampleInterface {
  type: EItemSampleType.GLOVES;
  variety: EItemSampleGloves;
}

export interface IItemSampleBoots extends IItemSampleInterface {
  type: EItemSampleType.BOOTS;
  variety: EItemSampleBoots;
}

export interface IItemSampleBelt extends IItemSampleInterface {
  type: EItemSampleType.BELT;
  variety: EItemSampleBelt;
}

export interface IItemSampleRing extends IItemSampleInterface {
  type: EItemSampleType.RING;
  variety: EItemSampleRing;
}

export interface IItemSampleNecklace extends IItemSampleInterface {
  type: EItemSampleType.NECKLACE;
  variety: EItemSampleNecklace;
}
