import { Routes } from '@angular/router';

import { LayoutPage } from './_layout/_.page';

import { hasAuth } from './user/user.service';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'splash',
  },

  {
    path: '',
    loadChildren: () =>
      import('./pages/_splash/splash.routes').then((m) => m.SplashRoutes),
  },

  {
    path: '',
    component: LayoutPage,
    canActivate: [hasAuth],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.routes').then((m) => m.HomeRoutes),
      },

      {
        path: '',
        loadChildren: () =>
          import('./pages/auction/auction.routes').then((m) => m.AuctionRoutes),
      },

      {
        path: '',
        loadChildren: () =>
          import('./pages/community/community.routes').then(
            (m) => m.CommunityRoutes,
          ),
      },

      // {
      //   path: '',
      //   loadChildren: () =>
      //     import('./pages/payment/payment.routes').then((m) => m.PaymentRoutes),
      // },
    ],
  },
];
