import { Component } from '@angular/core';

import { BaseComponent, BaseModule } from 'client/modules/_base/base';

@Component({
  selector: 'lc-layout-header-block',
  standalone: true,
  imports: [BaseModule],
  templateUrl: './header.block.html',
})
export class LayoutHeaderBlock extends BaseComponent {}
