import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  INJECTOR,
  Input,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule, MatDrawer } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';

import { InViewportAction, InViewportModule } from 'ng-in-viewport';

import { fromNano, toNano } from '@ton/core';

import { BehaviorSubject } from 'rxjs';

import { AuctionService } from 'client/modules/pages/auction/auction.service';

import { CommunityFriendService } from 'client/modules/pages/community/modules/friend/friend.service';
import { CommunityReferralService } from 'client/modules/pages/community/modules/referral/referral.service';

import { PaymentService } from 'client/modules/pages/payment/payment.service';
import { UserService } from 'client/modules/user/user.service';

import {
  hasNextPage,
  hasPreviousPage,
  nextPage,
  telegramUserReferralUrl,
} from 'helpers';

import {
  EPaymentStatus,
  EPaymentType,
  IPagination,
  IQuery,
  IUser,
} from 'interfaces';

const modules = [
  AsyncPipe,
  JsonPipe,
  NgForOf,
  NgIf,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ScrollingModule,

  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatListModule,
  MatSidenavModule,
  MatExpansionModule,
  MatSelectModule,

  InViewportModule,
];

@NgModule({
  imports: modules,
  providers: [HttpClient, AuctionService, PaymentService, UserService],
  exports: modules,
})
export class BaseModule {}

@Component({
  selector: 'lc-base-helper',
  standalone: true,
  imports: [],
  providers: [PaymentService],
  template: ``,
})
export class BaseComponent {
  @Input()
  public drawer!: MatDrawer;

  protected readonly injector = inject(INJECTOR);
  protected readonly destroyRef = inject(DestroyRef);

  protected readonly router = inject(Router);

  protected readonly cdr = inject(ChangeDetectorRef);

  protected readonly dialog = inject(MatDialog);

  protected readonly auctionService = inject(AuctionService);
  protected readonly communityFriendService = inject(CommunityFriendService);
  protected readonly communityReferralService = inject(
    CommunityReferralService,
  );
  public readonly userService = inject(UserService);
  public readonly paymentService = inject(PaymentService);

  public readonly users$: BehaviorSubject<IUser[]> = new BehaviorSubject<
    IUser[]
  >([]);

  protected query: IQuery = {};
  public pagination: IPagination = {
    page_index: 0,
    page_size: 0,
    count: 0,
  };
  protected loading: boolean = true;

  public readonly hasNextPage = hasNextPage;
  public readonly hasPreviousPage = hasPreviousPage;
  public readonly nextPage = nextPage;
  public readonly telegramUserReferralUrl = telegramUserReferralUrl;

  public fromNano = fromNano;
  public toNano = toNano;

  public readonly EPaymentType = EPaymentType;
  public readonly EPaymentStatus = EPaymentStatus;

  // Just for extends
  protected find() {}

  handleNextPage(event: InViewportAction): void {
    if (hasNextPage(this.pagination) && event.visible) {
      this.query.page_index = nextPage(this.pagination);

      this.find();
    }
  }
}
