import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { BaseComponent, BaseModule } from 'client/modules/_base/base';

@Component({
  selector: 'lc-root',
  standalone: true,
  imports: [RouterOutlet,  BaseModule],
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    this.userService.user$.subscribe();
  }
}
