<mat-toolbar *ngIf="userService.user$ | async as user" class="d-flex align-items-center justify-content-between">
123
</mat-toolbar>




<!--<header-->
<!--  *ngIf="userService.user$ | async as user"-->
<!--  class="d-flex align-items-center justify-content-between"-->
<!--&gt;-->
<!--  <div-->
<!--    class="d-flex align-items-center justify-content-center tui-space_vertical-3"-->
<!--  >-->
<!--    <tui-avatar-->
<!--      [src]="user.photo_url | tuiFallbackSrc: '@tui.user' | async"-->
<!--      size="l"-->
<!--    />-->

<!--    <div class="tui-space_left-3 d-flex flex-column justify-content-center">-->
<!--      <span> {{ user.full_name }} </span>-->

<!--      <div class="d-flex align-items-center">-->
<!--        <label tuiProgressLabel>-->
<!--          40 \ 100-->
<!--          <progress tuiProgressBar size="l" [max]="100" [value]="40"></progress>-->
<!--        </label>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div>-->
<!--    <tui-chip appearance="whiteblock">-->
<!--      <img src="assets/images/currency/ton.svg" />-->

<!--      {{ fromNano(user.balance) }}-->
<!--      <button-->
<!--        tuiIconButton-->
<!--        iconStart="@tui.plus"-->
<!--        [routerLink]="['/payment']"-->
<!--      ></button>-->
<!--    </tui-chip>-->

<!--    <button-->
<!--      tuiButton-->
<!--      appearance="outline"-->
<!--      size="m"-->
<!--      iconStart="@tui.mail"-->
<!--      class="tui-space_left-3"-->
<!--    ></button>-->

<!--    <button-->
<!--      tuiButton-->
<!--      appearance="outline"-->
<!--      size="m"-->
<!--      iconStart="@tui.settings"-->
<!--      class="tui-space_left-3"-->
<!--    ></button>-->
<!--  </div>-->
<!--</header>-->
